import React from 'react';

const BurgerButton = (props) => {
  return (
    <div className="flex flex-col justify-around w-8 h-8 bg-transparent cursor-pointer" data-open={props.open} onClick={props.onClick}>
      <div className="w-8 h-1 bg-white rounded-sm relative transition-all duration-300 ease-linear"/>
      <div className="w-8 h-1 bg-white rounded-sm relative transition-all duration-300 ease-linear"/>
      <div className="w-8 h-1 bg-white rounded-sm relative transition-all duration-300 ease-linear"/>
    </div>
  )
}

export default BurgerButton;
