import React from "react"
//import * as containerStyles from "../styles/container.module.css"

function ContainerFluid({ children }) {
  return (
    <div className="w-full bg-white text-slate-900 dark:bg-dark-900 dark:text-slate-300">{children}</div>
  )
}

export default ContainerFluid
