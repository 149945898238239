import React from 'react';

const Overlay = props => {
  return (
    <div
      className="overlay flex flex-col justify-center text-slate-100 bg-dark-600 h-screen w-full p-8 absolute top-0 left-0 -translate-x-full transition-transform duration-300 ease-in-out z-[3]"
      data-open={props.open}
    >
      {props.children}
    </div>
  )
}

export default Overlay;
