import { useState, useEffect } from "react";

const useThemeButton = () => {

  let websiteTheme
  if (typeof window !== `undefined`) {
    websiteTheme = window.__theme
  }
  const [theme, setTheme] = useState(websiteTheme);

  useEffect(() => {
    setTheme(window.__theme);

    window.__onThemeChange = () => {
      setTheme(window.__theme);
    };
  }, [theme]);

  const toggleTheme = theme => {
    window.__setPreferredTheme(theme);
    setTheme(theme);
  };

  return [theme, toggleTheme];
};

export default useThemeButton;
