import * as React from "react"
import { useContext } from 'react';
import { UserContext } from './usercontext';

// This component displays name from Context
const ReturnUser = () => {
  const user = useContext(UserContext);

  return (
      <span>
        {user.name}
      </span>
  );
};

export default ReturnUser;
