import * as React from "react"
import PropTypes from "prop-types"

import Breakpoint from "../components/breakpoint"
import MobileNav from "../components/mobilenav"
import DesktopNav from "../components/desktopnav"

const Header = ({ siteTitle }) => (
  <header className="bg-dark-600">
    <Breakpoint at="xs">
      <MobileNav/>
    </Breakpoint>
    <Breakpoint at="sm">
      <MobileNav/>
    </Breakpoint>
    <Breakpoint at="md">
      <DesktopNav/>
    </Breakpoint>
    <Breakpoint at="lg">
      <DesktopNav/>
    </Breakpoint>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
