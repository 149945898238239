/**
 * Implement Gatsby's SSR (Server Side Rendering) APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/ssr-apis/
*/

import React from "react"
import { UserProvider } from "./src/components/usercontext"

import Layout from "./src/components/layout"

export const wrapRootElement = ({ element }) => (
  <UserProvider>{element}</UserProvider>
)

/* dark mode code*/
export const onRenderBody = ({ setPreBodyComponents }) => {
  setPreBodyComponents([
    React.createElement("script", {
      dangerouslySetInnerHTML: {
        __html: `
          (() => {
            window.__onThemeChange = function() {};
            function setTheme(newTheme) {
              window.__theme = newTheme;
              preferredTheme = newTheme;
              document.body.className = newTheme;
              window.__onThemeChange(newTheme);
            }
            let preferredTheme
            try {
              preferredTheme = localStorage.getItem('theme')
            } catch (err) {}
            window.__setPreferredTheme = newTheme => {
              setTheme(newTheme)
              try {
                localStorage.setItem('theme', newTheme)
              } catch (err) {}
            }
            let darkQuery = window.matchMedia('(prefers-color-scheme: dark)')
            darkQuery.addListener(e => {
              window.__setPreferredTheme(e.matches ? 'dark' : 'light')
            })
            setTheme(preferredTheme || (darkQuery.matches ? 'dark' : 'light'))
          })()
        `,
      },
    }),
  ])
}

export const wrapPageElement = ({ element, props }, pluginOptions) => {
  return (
    <Layout {...props} pluginOptions={pluginOptions}>
      {element}
    </Layout>
  )
}
