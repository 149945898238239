import * as React from "react"
import { useState } from "react";
import { bool, func } from 'prop-types';

import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMoon, faSun } from "@fortawesome/free-solid-svg-icons"

import BurgerButton from "../components/burgerbutton"
import Overlay from "../components/overlay"
import ReturnUser from "../components/returnuser"
/*import ThemeButton from "../components/themebutton";*/
import useThemeButton from "../components/usethemebutton";

// Theme Toggle
const Toggle = () => {
    const [theme, toggleTheme] = useThemeButton()

    if (theme == null) {
      return null
    }

    return (
          <label>
            <input
                  type="checkbox"
                  style={{ display: `none` }}
                  onChange={e => toggleTheme(e.target.checked ? 'dark' : 'light')}
                  checked={theme === 'dark'}
            />{' '}
            <FontAwesomeIcon
              icon={theme === "dark" ? faMoon : faSun}
              className="cursor-pointer text-slate-100 text-3xl"
            />
          </label>
    )
}
// Compontent
const MobileNav = props => {
  // Overlay State
  const [open, setOpen] = useState(false);
  const overlayToggle = () => {
    setOpen(!open)
  }
  return (
    <>
      <nav className="flex flex-row justify-end items-center my-0 mx-auto p-4 max-w-screen-lg">
        <Link to="/" className="grow z-10">
          <StaticImage
            src="../images/am-logo-white.png"
            width={120}
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="McInnes Design Icon"
            loading="eager"
            layout="constrained"
            placeholder="blurred"
            /*style={{ marginBottom: `1.45rem` }}*/
          />
        </Link>
        <ul className="flex item-center justify-end grow gap-5 m-0 z-10">
          <Toggle/>
          <BurgerButton open={open} onClick={overlayToggle}/>
        </ul>
      </nav>

      <Overlay open={open}>
        <Link to="/about/" className="text-2xl text-slate-100 text-center px-8 py-2 transition-colors duration-300 ease-linear" onClick={overlayToggle}>Link 1</Link>
        <Link to="/about/" className="text-2xl text-slate-100 text-center px-8 py-2 transition-colors duration-300 ease-linear" onClick={overlayToggle}>Link 2</Link>
        <Link to="/about/" className="text-2xl text-slate-100 text-center px-8 py-2 transition-colors duration-300 ease-linear" onClick={overlayToggle}>Link 3</Link>
        <Link to="/about/" className="text-2xl text-slate-100 text-center px-8 pt-2 pb-8 transition-colors duration-300 ease-linear" onClick={overlayToggle}>Link 4</Link>
        <span className="text-base text-slate-100 text-center px-8 py-2 border-2 border-dark-600 border-t-slate-100">Hi, <ReturnUser/></span>
      </Overlay>
    </>
  )
}

MobileNav.propTypes = {
  open: bool.isRequired,
  setOpen: func.isRequired,
};

export default MobileNav
