import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { OutboundLink } from "gatsby-plugin-google-gtag"

const ListLink = props => (
  <li className="block mb-3 hover:text-cyan-300">
    <Link to={props.to}>{props.children}</Link>
  </li>
)

const Footer = props => (
  <footer className="text-slate-100 bg-dark-600 py-16">
    <div className="footerFlex flex flex-wrap max-w-full">
      <div className="footerFlexBox text-center grow">
        <Link to="#">
          <StaticImage
            src="../images/am-logo-white.png"
            width={120}
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="McInnes Design Icon"
            layout="constrained"
            placeholder="blurred"
            style={{ marginBottom: `1.45rem` }}
          />
        </Link>
      </div>
      <div className="footerFlexBox text-center grow">
        <ul style={{ listStyle: `none`}}>
          <ListLink to="/">Home</ListLink>
          <ListLink to="/about/">About</ListLink>
          <ListLink to="/contact/">Contact</ListLink>
        </ul>
      </div>
      <div className="footerFlexBox text-center grow">
        <ul style={{ listStyle: `none`}}>
          <ListLink to="/">Home</ListLink>
          <ListLink to="/thank-you/">Thank You</ListLink>
          <ListLink to="/contact/">Contact</ListLink>
        </ul>
      </div>
      <div className="footerFlexBox text-center grow">
        <ul style={{ listStyle: `none`}}>
          <ListLink to="/">Home</ListLink>
          <ListLink to="/about/">About</ListLink>
          <ListLink to="/contact/">Contact</ListLink>
        </ul>
      </div>
    </div>
    <div className="flex flex-col sm:flex-row flex-wrap items-center justify-center">
      <p style={{ margin: `1.45rem 0` }}>Built and deployed using</p>
    </div>
    <div className="flex flex-col sm:flex-row flex-wrap items-center justify-center">
      <StaticImage
        src="../images/Gatsby_Logo_White.png"
        width={150}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="Gatsby Logo"
        layout="constrained"
        placeholder="blurred"
        style={{ margin: `0 1.45rem` }}
      />
      <p className="my-auto mx-0 text-2xl">+</p>
      <StaticImage
        src="../images/Netlify_Logo_White.png"
        width={150}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="Netlify Logo"
        layout="constrained"
        placeholder="blurred"
        style={{ margin: `0 1.45rem` }}
      />
    </div>
    <div className="flex flex-col sm:flex-row flex-wrap items-center justify-center m-6">
      © {new Date().getFullYear()}, A website by&nbsp;
      <OutboundLink href="https://acmcinnes.com.au/" className="hover:text-cyan-300">
        Andrew McInnes
      </OutboundLink>
      &nbsp;ABN: 32 205 159 015
    </div>
  </footer>
)

export default Footer
