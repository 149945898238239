/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
*/

import React from "react"
import { UserProvider } from "./src/components/usercontext"

import { wrapPageElement } from "./gatsby-ssr"

import './src/styles/global.css'

export const wrapRootElement = ({ element }) => (
  <UserProvider>{element}</UserProvider>
)

export { wrapPageElement } from "./gatsby-ssr"
