import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMoon, faSun } from "@fortawesome/free-solid-svg-icons"

import ReturnUser from "../components/returnuser"
/*import ThemeButton from "../components/themebutton";*/
import useThemeButton from "../components/usethemebutton";

const ListLink = props => (
  <li className="text-slate-100 hover:text-cyan-300 inline-block my-0 mx-2">
    <Link to={props.to} className="p-4">{props.children}</Link>
  </li>
)

const Toggle = () => {
    const [theme, toggleTheme] = useThemeButton()

    if (theme == null) {
      return null
    }

    return (
          <label>
            <input
                  type="checkbox"
                  style={{ display: `none` }}
                  onChange={e => toggleTheme(e.target.checked ? 'dark' : 'light')}
                  checked={theme === 'dark'}
            />{' '}
            <FontAwesomeIcon
              icon={theme === "dark" ? faMoon : faSun}
              className="cursor-pointer text-slate-100"
            />
          </label>
    )
}

const DesktopNav = props => (
  <nav className="flex flex-row justify-end items-center my-0 mx-auto p-4 max-w-screen-lg">
    <Link to="/">
      <StaticImage
        src="../images/am-logo-white.png"
        width={120}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="McInnes Design Icon"
        loading="eager"
        layout="constrained"
        placeholder="blurred"
        /*style={{ marginBottom: `1.45rem` }}*/
      />
    </Link>
    <ul className="text-lg flex items-center justify-end grow m-0">
      <ListLink to="/about/">About</ListLink>
      <ListLink to="/about/">Link 2</ListLink>
      <ListLink to="/contact/">Contact</ListLink>
      <li className=" text-slate-100 inline-block my-0 mx-2">Hi, <ReturnUser/></li>
      <li className="inline-block my-0 mx-2"><Toggle/></li>
    </ul>
  </nav>
)

export default DesktopNav
